import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Header from './Header'; // Adjust the path accordingly
import Footer from './Footer';
import './Register.css'; // Import your CSS file

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  
  // Set your base URL correctly here
  const baseURL = process.env.REACT_APP_API_URL || 'https://ocr.thevidyalayam.com/api';

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const handleRegister = async () => {
    try {
      const response = await axiosInstance.post('/register', {
        name,
        username,
        password,
        confirm_password: confirmPassword,
      });
  
      console.log('Response:', response);
  
      if (response.status === 201) {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Username already exists or invalid data');
    }
  };

  return (
    <div>
      <Header />
      <div className="register-container">
        <h2>Register</h2>
        <div className="input-container">
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="input-container">
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="input-container">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-field"
          />
        </div>
        <div className="input-container">
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="input-field"
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button className="register-button" onClick={handleRegister}>
          Register
        </button>
        <p className="login-link">
          Already have an account? <Link to="/login">Login here</Link>.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
